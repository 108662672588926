import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"

import './src/assets/css/index.scss'

let dropdownClicked = false; // Flag to track dropdown clicks
let anchorMenuItemClicked = false; // Flag to track anchor menu item clicks

// Add an event listener to the first element with the class "dropdown" (ensure it runs on the client side)
if (typeof window !== 'undefined') {
  const dropdownButton = document.querySelector('.dropdown');
  if (dropdownButton) {
    dropdownButton.addEventListener('click', () => {
      dropdownClicked = true; // Set the flag to true when the dropdown is clicked
    });
  }

  // Add an event listener to detect hash fragment changes in the URL
  window.addEventListener('hashchange', () => {
    anchorMenuItemClicked = true; // Set the flag to true when an anchor menu item is clicked
  });
}

export const onClientEntry = () => {
  if (typeof window !== 'undefined' && !dropdownClicked && !anchorMenuItemClicked) {
    const loaderWrapper = document.getElementById('loader-wrapper');
    if (loaderWrapper) {
      loaderWrapper.style.display = "block";
    }
  }
}

export const onPreRouteUpdate = () => {
  if (typeof window !== 'undefined' && !dropdownClicked && !anchorMenuItemClicked) {
    const loaderWrapper = document.getElementById('loader-wrapper');
    if (loaderWrapper) {
      loaderWrapper.style.display = "block";
    }
  }
}

export const onRouteUpdateDelayed = () => {
  if (typeof window !== 'undefined' && !dropdownClicked && !anchorMenuItemClicked) {
    const loaderWrapper = document.getElementById('loader-wrapper');
    if (loaderWrapper) {
      loaderWrapper.style.display = "block";
    }
  }
}

export const onRouteUpdate = () => {
  if (typeof window !== 'undefined' && !dropdownClicked && !anchorMenuItemClicked) {
    setTimeout(() => {
      const loaderWrapper = document.getElementById('loader-wrapper');
      if (loaderWrapper) {
        loaderWrapper.style.display = "none";
      }
    }, 1000);
  }
}
